@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

#root {
    height: auto;
    width: 100vw;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.5px;
}

html,body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    background-color: #0E0E0E;
}

::selection {
    background-color: #b339a746;
    color: white;
}

body::-webkit-scrollbar {
    display: none;
}