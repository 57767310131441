.navbar_con{
    height: clamp(60px, 10vh, 12vh);
    width: clamp(300px, 100vw, 100vw);
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar_con img{
    width: clamp(90px, 8vw, 10vw);
}
