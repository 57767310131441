
.headlines_section {
    height: 50vh;
    width: 100%;
    background-color: #ffffff;

    padding: 0px clamp(30px, 10vw, 20vw);
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding-bottom: 20px;
}

.headlines_section h3 {
    font-size: 60px;
    font-weight: 100;
    letter-spacing: -1px;
    width: 550px;
    opacity: 0;
}

.headlines_section h3:first-child {
    margin-bottom: 100px;
    color: #B339A6;
}

.headlines_section h3:last-child {
    margin-top: 100px;
    text-align: end;
    color: #B339A6;
}

/* ---------------- Service Section starts ------------------------- */

.cards_section {
    height: 120vh;
    width: 100%;
    background-color: #ffffff;

    position: relative;

    display: flex;
}

.cards_background{
    height: inherit;
    width: inherit;
    overflow: visible;
}

.cards_background img:nth-child(1) {
    width: 1700px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* filter: brightness(100%) contrast(100%); */
    /* transform: translateX(-50%); */
    overflow: visible;
}

.cards_background img:nth-child(2) {
    width: 1000px;
    position: absolute;
    top: -10%;
    left: -20%;
    animation: rotate 3s linear infinite;
}

.cards_background img:nth-child(3) {
    width: 1000px;
    position: absolute;
    right: -30%;
    top: 0%;
    animation: rotate 2s linear infinite;
}

.card_con {
    height: 70vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0);

    position: absolute;
    top: 12%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;

    animation: marquee 90s linear infinite;
}

.card {
    width: 340px;
    height: 460px;

    border-radius: 32px;
    border: 0.7px solid #BCBCBC;
    background: #F7F7F7;
    box-shadow: 0px 76px 76px 0px rgba(97, 97, 97, 0.116);
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    transition: 0.5s;
}

.card:nth-child(even){
    margin-bottom: 150px;
}

.card:nth-child(odd){
    margin-top: 150px;
}


.card:nth-child(even):hover{
    transform: rotate(3deg) scale(1.4);
}

.card:nth-child(odd):hover{
    transform: rotate(-3deg) scale(1.4);
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

/* ---------------- Service Section starts ---------------------------------------- */

.services_section {
    height: 100vh;
    width: 100vw;
    background-color: rgb(255, 255, 255);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px;
}

.services_section h2 {
    font-size: 60px;
    font-weight: 100;
    color: #a3469a;
}

/* ------------------------- extras --------------------*/

.locomotive_div{
    /* max-height: auto; */
    width: 100%;
}